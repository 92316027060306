
const RegistrationService = {}

const brackenFields = {}
const brackenFullFields = {}
// eslint-disable-next-line no-undef
const brackenUrl = document.gBrackenServerUrl?document.gBrackenServerUrl:"http://live.pathwaysawarua.com.david.work"
brackenFields.fields = [{
	"name": "firstname",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^.+$"
}, {
	"name": "lastname",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^.+$"
}, {
	"name": "email",
	"value": "",
	"type": 2,
	"remoteCode": 0,
	"validation": "^[a-zA-Z0-9\\.\\-_'\\+\\*]+@[a-z0-9]+([_\\-]?[a-zA-Z0-9]+)*([.]{1})[a-z0-9]+([.]?[a-z0-9]+)*$"
}, {
	"name": "mobile",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^[0-9\\-()\\+]{9,15}$"
}, {
	"name": "username",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^[a-zA-Z0-9_-]{1,100}$"
}, {
	"name": "password",
	"value": "",
	"type": 1,
	"remoteCode": 0,
	"validation": "^\\S+$"
}, {
	"name": "confirmpassword",
	"value": "",
	"type": 1,
	"remoteCode": 0,
	"validation": "^\\S+$"
},
{
	"name": "joincode",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^[!\"#$%&'()*+./:;<=>?@^_`{|}~\\-0-9a-zA-Z]{2,50}(,\\s*[!\"#$%&'()*+./:;<=>?@^_`{|}~\\-0-9a-zA-Z]{2,50})*$"
}, {
	"name": "termsandconditions",
	"value": "true",
	"type": 3,
	"remoteCode": 0,
	"validation": "^true$"
}, {
	"name": "submit",
	"value": null,
	"type": 4,
	"remoteCode": 0
}
];

brackenFullFields.fields = [{
	"name": "firstname",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^.+$"
}, {
	"name": "lastname",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^.+$"
}, {
	"name": "email",
	"value": "",
	"type": 2,
	"remoteCode": 0,
	"validation": "^[a-zA-Z0-9\\.\\-_'\\+\\*]+@[a-z0-9]+([_\\-]?[a-zA-Z0-9]+)*([.]{1})[a-z0-9]+([.]?[a-z0-9]+)*$"
}, {
	"name": "mobile",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^[0-9\\-()\\+]{9,15}$"
}, {
	"name": "username",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^[a-zA-Z0-9_-]{1,100}$"
}, {
	"name": "password",
	"value": "",
	"type": 1,
	"remoteCode": 0,
	"validation": "^\\S+$"
}, {
	"name": "confirmpassword",
	"value": "",
	"type": 1,
	"remoteCode": 0,
	"validation": "^\\S+$"
},
{
	"name": "joincode",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^[!\"#$%&'()*+./:;<=>?@^_`{|}~\\-0-9a-zA-Z]{2,50}(,\\s*[!\"#$%&'()*+./:;<=>?@^_`{|}~\\-0-9a-zA-Z]{2,50})*$"
}, 
{
	"name": "Institution",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^.+$"
}, {
	"name": "Organisation",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^.+$"
}, {
	"name": "Class",
	"value": "",
	"type": 0,
	"remoteCode": 0,
	"validation": "^.+$"
}, {
	"name": "option",
	"value": 0,
	"type": 7,
	"remoteCode": 0
},{
	"name": "termsandconditions",
	"value": "true",
	"type": 3,
	"remoteCode": 0,
	"validation": "^true$"
}, {
	"name": "submit",
	"value": null,
	"type": 4,
	"remoteCode": 0
}
];

// eslint-disable-next-line no-unused-vars
const options = [[0, "Learner"], [11415, "Employer"], [3010, "Secondary"], [3011, "Tertiary"]];
const joinCodes = {
	"Employer":"EMPLOY",
	"Secondary":"F42BC1",
	"Tertiary":"B12C13",
	"Cultural":"03D975"
};


/**
 * Try and register
 *
 * @param {Array<FormFields>} fields
 * @return {string} 
 */
RegistrationService.TryRegister = (fields) => {
	return new Promise((resolve, reject) => {


		brackenFields.fields.find(i => i.name === 'firstname').value = fields.firstname;
		brackenFields.fields.find(i => i.name === 'lastname').value = fields.lastname;
		brackenFields.fields.find(i => i.name === 'email').value = fields.email;
		brackenFields.fields.find(i => i.name === 'mobile').value = '021456789';
		brackenFields.fields.find(i => i.name === 'username').value = fields.username;
		brackenFields.fields.find(i => i.name === 'password').value = fields.password;
		brackenFields.fields.find(i => i.name === 'confirmpassword').value = fields.confirmpassword;
		brackenFields.fields.find(i => i.name === 'joincode').value = fields.joincode;
		brackenFields.fields.find(i => i.name === 'termsandconditions').value = fields.termsandconditions;
		brackenFields.fields.find(i => i.name === 'submit').value = null;
		//var isValid = true;
		var errorFields = [];
		brackenFields.fields.forEach(element => {
			if (element.validation) {
				let re = new RegExp(element.validation)
				let result = re.test(element.value)
				if (!result) {
					//isValid = false;
					errorFields.push(element.name)
				}
			}
		});

		//console.log(brackenFields)
		if (errorFields.length === 0) {
			// no errors lets try and send.
			// https://live.pathwaysawarua.com.staging.brackenserver.net/rest2/remotelogin/remoteregister/register
			const url = brackenUrl + "/rest2/remotelogin/remoteregister/register"
		
			/////////////////////////////////////
			fetch(url, {
  method: 'POST', // or 'PUT'
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(brackenFields),
})
.then(response => response.text())
.then(data => {
  console.log('Success:', data);
	if(data.startsWith("{")){

		let odata = JSON.parse(data);	
		if(odata && Array.isArray(odata.fields)){
		// error from bracken server
		resolve({rtype:1,body:odata.fields})
		}
	}else{
		// no errors from bracken so lets redirect.
		if(typeof(data) === "string"){
			let  url = data.replace(/\$\{username\}/g, fields.username);
			//data.replace("${username}",fields.username);
			document.location.assign(url);
		}
	}	
})
.catch((error) => {
  console.error('Error:', error);
	reject(error)
});
			/////////////////////////////////////

		} else {
			resolve({rtype:0,body:errorFields});
		}
	});
};

RegistrationService.TryRegisterFull = (fields) => {
	return new Promise((resolve, reject) => {
		
		let optionIdx = 0;
		if (fields.formname === 'LearnerForm')
			optionIdx = 0;

		if (fields.formname === 'cultural')
			optionIdx = 0;

		if (fields.formname === 'secondary'){
			optionIdx = 2;
			fields.joincode = joinCodes.Secondary;
		}

		if (fields.formname === 'teriary'){
			optionIdx = 3;
			fields.joincode = joinCodes.Tertiary;
		}

		if (fields.formname === 'employer'){
			optionIdx = 1;
			fields.joincode = joinCodes.Employer;
		}

			brackenFullFields.fields.find(i => i.name === 'firstname').value = fields.firstname;
			brackenFullFields.fields.find(i => i.name === 'lastname').value = fields.lastname;
			brackenFullFields.fields.find(i => i.name === 'email').value = fields.email;
			brackenFullFields.fields.find(i => i.name === 'mobile').value = '021456789';
			brackenFullFields.fields.find(i => i.name === 'username').value = fields.username;
			brackenFullFields.fields.find(i => i.name === 'password').value = fields.password;
			brackenFullFields.fields.find(i => i.name === 'confirmpassword').value = fields.confirmpassword;
			brackenFullFields.fields.find(i => i.name === 'Institution').value = fields.orgitem.id
			brackenFullFields.fields.find(i => i.name === 'Organisation').value = fields.orgitem.Name;
			brackenFullFields.fields.find(i => i.name === 'Class').value = fields.Class;
			brackenFullFields.fields.find(i => i.name === 'option').value = Number(options[optionIdx][0]);
			brackenFullFields.fields.find(i => i.name === 'joincode').value = fields.joincode;
			brackenFullFields.fields.find(i => i.name === 'termsandconditions').value = fields.termsandconditions;
			brackenFullFields.fields.find(i => i.name === 'submit').value = null;

			var errorFields = [];
			brackenFullFields.fields.forEach(element => {
				if (element.validation) {
					let re = new RegExp(element.validation)
					let result = re.test(element.value)
					if (!result) {
						//isValid = false;
						errorFields.push(element.name)
					}
				}
			});

			if(errorFields.length > 0){
				resolve({rtype:0,body:errorFields});
			}else{
				const url = brackenUrl + "/rest2/remotelogin/remotefullregister/fullregister";
				fetch(url,{
					method:'POST',
					headers:{'Content-Type':'application/json'},
					mode: 'cors',
					body:JSON.stringify(brackenFullFields)
				})
				.then(response => response.text())
				.then(data =>{
					if(data.startsWith("{")){
						let odata = JSON.parse(data);	
						if(odata && Array.isArray(odata.fields)){
						// error from bracken server
						resolve({rtype:1,body:odata.fields})
						}
					}else{
						// no errors from bracken so lets redirect.
						if(typeof(data) === "string"){
							let  url = data.replace(/\$\{username\}/g, fields.username);
							//data.replace("${username}",fields.username);
							document.location.assign(url);
						}
					}
				})
				.catch(err =>{
					reject(err);
				})
			}
	});
}

RegistrationService.GetBrackenServerUrl = () =>{
	return brackenUrl;
}

export default RegistrationService;

/* */