import React from 'react';
// import logo from './logo.svg';
import {BrowserRouter as Router, Routes , Route, Link } from "react-router-dom";
import Home from "./Home";
import Pathways from './Pathways';
import Educator from './Educator';
function App() {
  return (
		<Router>
    <div className="App">
     
        <div className="AppInner">
          {/* <div>
            <Link to="/">HOME</Link> <Link to="/learner">LEARNERS</Link> <Link to="/pathways/numeracy">Learners-Numeracy</Link> <Link to="/educator">Educator</Link> <Link to="/educator/pathways">Educator-Pathways</Link> <Link to="/educator/cultural">Educator-Cultural</Link>
          </div> */}
          <div className="AppContent">
						<Routes >
            	<Route path="/" element={<Home/>} />
							<Route path="/learner" element={<Pathways/>} />
							<Route path="/pathways/:pageid" element={<Pathways/>} />
							<Route path="/educator" element={<Educator/>} />
							<Route path="/educator/:pageid" element={<Educator/>} />
						</Routes >
          </div>
        </div>
      
    </div>
		</Router>
  );
}
export default App;