import React, {useEffect, useState} from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import TypeAheadDropDown from './th/TypeAheadDropDown';
// import LaunchIcon from '@material-ui/icons/Launch';

export default function EducatorsForm(props) {

  const [msgIdx,setMsgIdx] = useState(0);
	const [className, setClassname] = useState('');

	const registerClick = (formname) => {
		props.registerClick(formname);
	}
	const handleSetStep = (step) => {
		props.handleSetStep();
	}

	const formname = props.formname;
	const errors = props.formErrors;	

	const messages = [
		["I can't find my organisation","notlisted?t=e"],
		["I can't find my school","notlisted?t=t"],
		["I can't find my company","notlisted?t=c"],
	]
    
	const onFirstNameBlur = (ev) => {
		let newName = `${ev.target.value}'s class`;

		if(props.formData['Class'].length < 1 && newName.length > 1) {			
				props.formData['Class'] = newName;
				setClassname(newName);			
		} 
	}

	useEffect(() =>{

		switch(props.formname){
			case 'teriary':
				setMsgIdx(0);
			break;
			case 'secondary':
				setMsgIdx(1);
			break;
			case 'employer':
				setMsgIdx(2);
			break;
			default:
				setMsgIdx(0);
		}
	},[props.formname])

	return (
		<div className="pa-f-learner">
			<Grid className="pa-modal-content" container minWidth="md">
				<Grid className="viewLeft" item xs={12} sm={4}>
					<div className="leftHeading">Create a class on<br />Pathways Awarua</div>
					<div className="line blue"></div>
					<p>By signing up for Pathways Awarua you agree to our:</p>
					<div><a href="//live.pathwaysawarua.com/terms.aspx" rel="noreferrer" target="_blank">Terms and Conditions</a></div>
					<div><a href="/privacy" target="_blank">Privacy Policy</a></div>
				</Grid>
				<Grid className="viewRight" item xs={12} sm={8}>
					<div><h3>{props.title}</h3></div>
					<Grid container spacing={1}>
						<Grid item xs={12} sm={6}>
							<TextField error={errors['firstname'].error} helperText={errors['firstname'].msg} key="firstname" name="firstname" value={props.formData['firstname']} onChange={props.change} onBlur={onFirstNameBlur} label="First name" type="text" fullWidth={true} variant="outlined" size="small"></TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField error={errors['lastname'].error} helperText={errors['lastname'].msg} key="lastname" name="lastname" value={props.formData['lastname']} onChange={props.change} label="Last name" type="text" fullWidth={true} variant="outlined" size="small"></TextField>
						</Grid>
					</Grid>
					<Grid item className="frow" spacing={1}><TextField error={errors['email'].error} helperText={errors['email'].msg} className="w100" key="email" name="email" value={props.formData['email']} onChange={props.change} label="Email address" type="text" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
					<Grid item className="frow" spacing={1}><TextField error={errors['username'].error} helperText={errors['username'].msg} className="w100" key="username" name="username" value={props.formData['username']} onChange={props.change} label="Username" type="text" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
					<Grid item className="frow" spacing={1}><TextField error={errors['password'].error} helperText={errors['password'].msg} className="w100" key="password" name="password" value={props.formData['password']} onChange={props.change} label="Password" type="password" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
					<Grid item className="frow" spacing={1}><TextField error={errors['confirmpassword'].error} helperText={errors['confirmpassword'].msg} className="w100" key="confirmpassword" name="confirmpassword" value={props.formData['confirmpassword']} onChange={props.change} label="Confirm password" type="password" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
					<Grid item className="frow" spacing={1}><TypeAheadDropDown error={errors['Organisation'].error} items={props.items} text={props.selectedOrgText} handleSelectItem={(v) => { props.onItemSelected(v) }}></TypeAheadDropDown></Grid>
					<div className="th-error"><span>{errors['Organisation'].msg}</span></div>
					<Grid item className="frow" spacing={1} ><TextField data-auto-name={className} error={errors['Class'].error} helperText={errors['Class'].msg} key="class" name="Class" value={props.formData['Class']} onChange={props.change} label="Class name" type="text" fullWidth={true} variant="outlined" size="small" /></Grid>
					<div className="formFooter">
						<Button type="button" name={formname} className="rounded green" onClick={() => registerClick(formname)}>Register</Button>
						<div className="login">Already registered? <span className="link" onClick={() => { handleSetStep(0); }}>Login</span></div>
						<div className="login"><a href={messages[msgIdx][1]}>{messages[msgIdx][0]}</a></div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}