import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";

 const Educator = () => { 
	let { pageid } = useParams();
	const [pageHtml, setPageHtml] = useState("");
  useEffect(() => {
		let pagename = pageid?`educator_${pageid}`:"educator";
		fetch(`/pages/${pagename}.cshtml`)
    .then(response => response.text())
    .then(html => setPageHtml(html))
	},[pageid]);

	const Pagecontent = () =>{
		if(pageHtml && pageHtml.length > 10)
			return <div dangerouslySetInnerHTML={{__html: pageHtml.replace(/(<? *script)/gi, 'illegalscript')}} ></div>;
		else
			return <div>Document {pageid} was not found</div>
	}

		return(<Pagecontent />)
 }

 export default Educator