import React, { useState } from 'react';
import { Container, Grid, Button } from '@material-ui/core';
// import { withStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Dialog from '@material-ui/core/Dialog';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import LearnerForm from './LearnerForm';
import { Carousel } from 'react-responsive-carousel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import RegistrationService from './RegistrationService';


import './Home.css';
import EducatorTypeForm from './EducatorTypeForm';

function Home() {

  // const [count, setCount] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');

  const [userName, setUsername] = useState('');
  const [userPwd, setUserpwd] = useState('');

  const [open, setOpen] = useState(false);
  const [openEdu, setOpenEdu] = useState(false);
  // eslint-disable-next-line
  const [scroll, setScroll] = useState('body');

  const [isWorking, setIsWorking] = useState(false);

  // const theme = useTheme();
  const fullScreen = false; //useMediaQuery(theme.breakpoints.down('sm'));

  const formData = {};
  formData.firstname = '';
  formData.lastname = '';
  formData.email = '';
  formData.username = '';
  formData.password = '';
  formData.confirmpassword = ''
  formData.Class = '';
  formData.joincode = '';
  formData.termsandconditions = true;
  formData.formname = '';
  formData.orgitem = { id: 0, Name: '' };

  const [frmData, setfrmData] = useState(formData);

  const formErrors = {};
  formErrors.firstname = { error: false, msg: '' };
  formErrors.lastname = { error: false, msg: '' };
  formErrors.email = { error: false, msg: '' };
  formErrors.username = { error: false, msg: '' };
  formErrors.password = { error: false, msg: '' };
  formErrors.confirmpassword = { error: false, msg: '' };
  formErrors.Class = { error: false, msg: '' };
  formErrors.joincode = { error: false, msg: '' };
  formErrors.termsandconditions = { error: false, msg: '' };
  formErrors.Organisation = { error: false, msg: '' };
  formErrors.orgitem = { error: false, msg: '' };

  const [frmErrors, setfrmErrors] = useState(formErrors);

  const handleEducatorsClick = (evt) => {
    //console.log(tertiaryItems.length);
    //console.log(secondaryItems.length);
    //console.log(employerItems.length);
    setOpen(false);
    setOpenEdu(true);
  }
  const handleOpen = () => {
    setOpenEdu(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEdu(false);
  };

  const onTextChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = frmData;
    data[name] = value;
    setfrmData({ ...frmData, data });
    //console.log(frmData);
  }

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% + 90px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    color: '#1b90c2',
    fontWeight: 'bold',
  };

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const onLogin = async (evt) => {
    let ReturnUrl = params.ReturnUrl;
    let username = userName;
    let password = userPwd;

    let payload = {
      "fields": [
        { "name": "username", "value": "xxxxx", "type": 0, "remoteCode": 0, "validation": "^\\S+$" },
        { "name": "password", "value": "xxxxx", "type": 1, "remoteCode": 0, "validation": "^\\S+$" },
        { "name": "returnurl", "remoteCode": 0, "required": true, "type": 0, "value": "" },
        { "name": "submit", "value": null, "type": 4, "remoteCode": 0 }]
    };

    payload.fields[0].value = username;
    payload.fields[1].value = password;
    payload.fields[2].value = ReturnUrl;
    setIsWorking(true);
    // eslint-disable-next-line
    let hasError = await tryLogin(payload);
    setErrorMsg("Please check username or password");
    setIsWorking(false);
    // console.log(hasError);
  }

  const tryLogin = (brackenFields) => {
    let brackenUrl = document.gBrackenServerUrl ? document.gBrackenServerUrl : "http://live.pathwaysawarua.com.david.work";

    setErrorMsg("");
    return new Promise((resolve, reject) => {
      let url = `${brackenUrl}/rest2/remotelogin/remotelogin/login`;
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(brackenFields),
      })
        .then(response => response.text())
        .then(data => {
          // console.log('Success:', data);
          if (data.startsWith("{")) {
            let odata = JSON.parse(data);
            if (odata && Array.isArray(odata.fields)) {
              // error from bracken server
              resolve({ rtype: 1, body: odata.fields })
            }
          } else {
            // no errors from bracken so lets redirect.
            if (typeof (data) === "string") {
              let url = data.replace(/\$\{username\}/g, brackenFields.fields[0].value);
              //data.replace("${username}",fields.username);
              document.location.assign(url);
            }
          }
        })
        .catch(error => {
          console.error('Error:', error);
          reject(error)
        });

    });
  }

  const tryRegister = (formName) => {
    let name = formName;
    let data = frmData;
    data.formname = name;
    setfrmData({ ...frmData, data });
    console.log(name);

    RegistrationService.TryRegister(frmData)
      .then(value => {
        // console.log(value);
        let errors = frmErrors;

        for (const prop in errors) {
          errors[prop].error = false;
          errors[prop].msg = '';
        }

        // return type = 0 = initial validation errors
        if (value.rtype === 0) {
          if (Array.isArray(value.body)) {
            // For now assuming it is an array of errors


            value.body.forEach(element => {
              if (errors[element]) {
                errors[element].error = true;
                errors[element].msg = "Required";
                if (element === 'email')
                  errors[element].msg = "Email format not correct";
              }

            });
            setfrmErrors({ ...frmErrors, errors });
          }
        }
        // return type = 1 = errors from bracken 
        if (value.rtype === 1) {
          value.body.forEach(element => {
            if (element.remoteCode && element.remoteCode !== 0) {
              if (errors[element.name]) {
                errors[element.name].error = true;
                errors[element.name].msg = element.message ? element.message : "Value required."
              }
            }
          });
          setfrmErrors({ ...frmErrors, errors });
        }
      })
      .catch((reason) => {
        // handle reason;
        console.error(reason);
      });
  }

  const loginworking = isWorking ? "busy" : "notbusy";

  /*
  const resetFormData = () => {
    // Reset errors.
    let errors = frmErrors

    for (const prop in errors) {
      errors[prop].error = false;
      errors[prop].msg = '';
    }
    setfrmErrors({ ...frmErrors, errors });

    // reset data.
    let data = frmData
    for (const dprop in data) {
      data[dprop] = '';
    }

    setfrmData({ ...frmData, data });
  }
  */

  return (
    <div className="pa-app">
      <Hidden smDown>
        <section className="pa-carousel-top">
          <Carousel infiniteLoop={true} interval={10000} autoPlay={true} showThumbs={false} showStatus={false}>
            <div>
              <img src="/banner/literacy-numeracy-banner.jpg" alt="literacy-numeracy-banner" />
            </div>
            <div>
              <a className="pa-carousel-link" href="https://pathwaysawarua.com/webinars" target="_blank" rel="noreferrer">
                <img src="/banner/webinar-banner-2023.jpg" alt="webinar banner" />
              </a>
            </div>
            <div>
              <img src="/banner/banner-one.jpg" alt="banner one" />
            </div>
            <div>
              <img src="/banner/my-community.jpg" alt="My Community" />
            </div>
            <div>
              <img src="/banner/banner-two.jpg" alt="banner two" />
            </div>
            <div>
            <a className="pa-carousel-link" href="//pathwaysawarua.com/images/Pathways_Awarua_2024_Prize_Draws.pdf" target="_blank" rel="noreferrer">
              <img src="/banner/prizedraw-banner.jpg" alt="banner three" />
            </a>
            </div>
          </Carousel>
        </section>
      </Hidden>
      <section>
        <Container className="pa-login-container" maxWidth={false}>
          <div className="pa-login">
            <h1>Login</h1>
            <div className="pa-loginError">{errorMsg}</div>
            <Grid container justifyContent="center" className={loginworking}>
              <Grid item xs={12} sm={6} className="pa-login-user">
                <input disabled={isWorking} type="text" placeholder="Username" value={userName} onChange={e => setUsername(e.target.value)} autoComplete="off"></input>
              </Grid>
              <Grid item xs={12} sm={6} className="pa-login-password">
                <input disabled={isWorking} type="password" placeholder="Password" value={userPwd} onChange={e => setUserpwd(e.target.value)} autoComplete="off"></input>
                <Hidden smUp><br /></Hidden>
                <div className="pa-button-wrapper">
                  <Button onClick={onLogin} disabled={isWorking} >GO</Button>
                  {isWorking && <CircularProgress size={40} className="pa-buttonProgress" />}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6} className="pa-login-forgot"><div className="pa-forgot-password"><a href="https://pathwaysawarua.com/help/password">Forgot your password?</a></div></Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <section className="pa-register-section">
        <Container className="pa-register-container" maxWidth="lg">
          <div className="pa-register">
            <h1>Don’t have an account? Register for free here.</h1>
            <div className="pa-button-container">
              <span className="pa-register-btn pa-btn-learners"><div className="pa-btn-title" onClick={handleOpen}>Learners</div></span>
              <span className="pa-register-btn pa-btn-educators"><div className="pa-btn-title" onClick={handleEducatorsClick}>Educators</div><div className="pa-btn-sub">Secondary | Tertiary | Employers | Cultural Capabilities</div></span>
            </div>
          </div>
        </Container>
      </section>
      <Hidden smUp>
        <section>
          <img src="/images/pa-mobile-image.jpg" alt="Literacy and numeracy" className="pa-rsp-img" />
        </section>
      </Hidden>
      <section className="pa-why-section">
        <Container className="pa-why-container" maxWidth="md">
          <h1 className='pa-c'>Why Pathways Awarua Works</h1>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <div className="pa-why-ico-container">
                <img src="/images/relevant-ico.jpg" alt="" />
                <h2>Relevant, enagaging</h2>
                <div>Hundreds of modules to build capabilities. Free, fun and easy to use.</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className="pa-why-ico-container">
                <img src="/images/anywhere-ico.jpg" alt="" />
                <h2>Anywhere, anytime</h2>
                <div>Learners can access all pathways on any device. There are offline apps available too.</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className="pa-why-ico-container">
                <img src="/images/tailored-ico.jpg" alt="" />
                <h2>Tailored Learning</h2>
                <div>Educators can recommend
                  modules to learners, track
                  their progress and run
                  reports.</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className="pa-why-ico-container">
                <img src="/images/progress-ico.jpg" alt="" />
                <h2>Progress</h2>
                <div>Learners can gain points
                  and earn certificates.</div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className="pa-new-section">
        <Container className="pa-new-container" maxWidth="md">
          <div className="pa-c">
            <h1>What's New?</h1>
            <div className="pa-sub-h">Visit our <a href="https://www.facebook.com/pathwaysawarua/" target="_blank" rel="noreferrer">Facebook page</a> to keep up to date with new learning content, new functionality and monthly
              competitions. Join our <a href="https://www.facebook.com/groups/pathwaysawarua" target="_blank" rel="noreferrer">Educator Facebook Group</a> to network, share and collaborate.</div>
          </div>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <div className="pa-img-cnt">
                <img src="/images/whats-new-image.jpg" alt="Webinars" />
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className="pa-txt-cnt">
                <h1 className="pa-blue">Free webinars for Educators and Learners</h1>
                <p>We offer regular <strong>free</strong> webinars to help educators make the most of
                  Pathways Awarua. Simply register your interest via the button below
                  and we will be in touch with available dates/times.</p>
                <div className="pa-c pa-fb-btn-cnt"><a className="pa-fb-btn" href="https://pathwaysawarua.com/webinars" target="_blank" rel="noreferrer">Free webinars for educators</a></div>
              </div>
            </Grid>
          </Grid>
          <div className="pa-blue-divider"></div>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <div className="pa-img-cnt">
                <img src="/images/kiwi.jpg" alt="Kiwi lingo" />
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className="pa-txt-cnt">
                <h1 className="pa-blue">“Kiwi Lingo” WordFit module</h1>
                <p>Keen to suss out the beauty of Aotearoa's language and learn the meaning of “bach”, “shebang”, “wop-wops” and heaps of other classic Kiwi words? - No worries, we’ve got you covered!</p>
                
              </div>
            </Grid>
          </Grid>
          <div className="pa-blue-divider"></div>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <div className="pa-img-cnt">
                <img src="/images/android.jpg" alt="Digital Literacy" />
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className="pa-txt-cnt">
                <h1 className="pa-blue">“Digital Literacy” WordFit module</h1>
                <p>Crack the code of today’s digital world and master words like “phishing”, “malware”, “deepfake”, “cloud computing”, “roaming”, “artificial intelligence”, and plenty more tech terms.</p>
                
              </div>
            </Grid>
          </Grid>
          <div className="pa-blue-divider"></div>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <div className="pa-img-cnt">
                <img src="/images/reading.jpg" alt="Reading pathway" />
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className="pa-txt-cnt">
                <h1 className="pa-blue">Reading pathway - check your readiness!</h1>
                <p>One new module has been developed and added to the Reading pathway at Step 4. This module was designed so that learners can check if they are “ready” to be assessed against the NCEA literacy standard (reading). </p>
                <p>The module follows a similar format to the reading assessment as the learners practice answering questions from five different texts.</p>
              </div>
            </Grid>
          </Grid>
          <div className="pa-blue-divider"></div>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <div className="pa-img-cnt">
                <img src="/images/writing.jpg" alt="Writing pathway" />
              </div>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className="pa-txt-cnt">
                <h1 className="pa-blue">Writing pathway - check your readiness!</h1>
                <p>Three new modules have been developed and added to the Writing pathway at Step 4. These modules were designed so that learners can check their readiness to be assessed for the NCEA literacy standard (writing).</p>
                <p>The three modules cover the three different components of the writing assessment: multiple choice, planning and editing..</p>
              </div>
            </Grid>
          </Grid>

        </Container>
      </section>
      <Hidden smDown>
        <section className="pa-what-section">
          <Container className="pa-what-container pa-c" maxWidth="md">
            <h1>What our learners and educators say:</h1>
            <p className="pa-sub-h">Meet our learners who have used Pathways Awarua and our educators who have
              worked with them to improve their numeracy and literacy skills.</p>
          </Container>
        </section>

        <section className="pa-ourusers-section">
          <Container className="pa-what-container pa-c" maxWidth="sm">
            <Carousel infiniteLoop={true} interval={10000} autoPlay={true} showThumbs={false} showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: -5 }}>
                    <ArrowBackIosIcon />
                  </button>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: -5 }}>
                    <ArrowForwardIosIcon />
                  </button>
                )
              }>
              <div>
                <div className="videocontainer">
                  <h3>Educator: Nadine</h3>
                  <div>
                    Nadine has been using Pathways Awarua with her students in a Whanau Ara Mua programme for the past three years. Her students like the way Pathways Awarua is self-paced, uses real-life activities and has certificates that reward effort.
                  </div>
                  <div>
                    <video controls poster="https://pathwaysawarua.com/images/video/512px-nadine-thumbnail.jpg" width="512">
                      <source src="https://pathwaysawarua.com/images/video/PathwaysAwarua-Nadine.mp4" type="audio/mp4" />
                    </video>
                  </div>
                </div>
              </div>
              <div>
                <div className="videocontainer">
                  <h3>Educator: William</h3>
                  <div>
                    William is a hospitality tutor who uses Pathways Awarua to help his students with their numeracy and literacy skills. He has noticed great improvements in the skills of students who use Pathways Awarua in his 20-week programme.
                  </div>
                  <div>
                    <video controls poster="https://pathwaysawarua.com/images/video/512px-william-thumbnail.jpg" width="512">
                      <source src="https://pathwaysawarua.com/images/video/PathwaysAwarua-William.mp4" type="audio/mp4" />
                    </video>
                  </div>
                </div>
              </div>
              <div>
                <div className="videocontainer">
                  <h3>Learner: Casey</h3>
                  <div>
                    Casey is a great advocate for Pathways Awarua. She talks about how much her reading and maths has improved and how she uses the ideas to help her daughter. Casey uses Pathways Awarua in class, at home and while she’s waiting for the bus!
                  </div>
                  <div>
                    <video controls poster="https://pathwaysawarua.com/images/video/512px-casey-thumbnail.jpg" width="512">
                      <source src="https://pathwaysawarua.com/images/video/PathwaysAwarua-Casey.mp4" type="audio/mp4" />
                    </video>
                  </div>
                </div>
              </div>
              <div>
                <div className="videocontainer">
                  <h3>Learner: Amelia</h3>
                  <div>
                    Amelia has earned over 12,000 points on Pathways Awarua and thinks it’s "cool". She used to dislike reading but now describes it as a hobby and is aiming to be an early childhood teacher. She is using the road code modules to help get ready for her restricted driver licence.
                  </div>
                  <div>
                    <video controls poster="https://pathwaysawarua.com/images/video/512px-Amelia-thumbnail.jpg" width="512">
                      <source src="https://pathwaysawarua.com/images/video/PathwaysAwarua-Amelia.mp4" type="audio/mp4" />
                    </video>
                  </div>
                </div>
              </div>
              <div>
                <div className="videocontainer">
                  <h3>Learner: Tiare</h3>
                  <div>
                    Tiare is studying hospitality and wants to work on a cruise ship or in a luxury hotel. He uses Pathways Awarua everyday and says that he was “hooked” on it from the start. He left school not liking maths but finds it fun on Pathways Awarua.
                  </div>
                  <div>
                    <video controls poster="https://pathwaysawarua.com/images/video/512px-tiare-thumbnail.jpg" width="512">
                      <source src="https://pathwaysawarua.com/images/video/PathwaysAwarua-Tiare.mp4" type="audio/mp4" />
                    </video>
                  </div>
                </div>
              </div>
              <div>
                <div className="videocontainer">
                  <h3>Learner: Charlie</h3>
                  <div>
                    Charlie is studying hospitality to become a barista or bartender. He talks about the challenges he faced at school because of his poor literacy skills. He thanks Pathways Awarua for improving his reading and writing and is aiming to do all the modules "to perfection".
                  </div>
                  <div>
                    <video controls poster="https://pathwaysawarua.com/images/video/512px-Charlie-thumbnail.jpg" width="512">
                      <source src="https://pathwaysawarua.com/images/video/PathwaysAwarua-Charlie.mp4" type="audio/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </Carousel>
          </Container>
        </section>
      </Hidden>
      <section>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="pa-d-root" fullScreen={fullScreen} maxWidth="md" scroll={scroll}>
          <div className="pa-d-container">
            <div id="daihead" className="pa-d-header">
              <IconButton aria-label="close" className="pa-d-close-btn" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <LearnerForm formData={frmData} formErrors={frmErrors} onTextChange={onTextChange} handleClose={handleClose} handleRegister={tryRegister} />
          </div>
        </Dialog>

        <Dialog open={openEdu} onClose={handleClose} aria-labelledby="form-dialog-title" className="pa-d-root" fullScreen={fullScreen} maxWidth="md" scroll={scroll}>
          <div className="pa-d-container">
            <div id="daihead" className="pa-d-header">
              <IconButton aria-label="close" className="pa-d-close-btn" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <EducatorTypeForm handleClose={handleClose} />
          </div>
        </Dialog>
      </section>

    </div>
  );
}

export default Home;
