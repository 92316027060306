import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	mybutton:{
	//	background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: '#444',
    height: 38,
    padding: '0 3px',
		position: 'relative',
	//	top: 6,
    // left: 15,
		maxWidth:260,
		width:'100%',
		// margin:4,
		fontSize: 14,
		textTransform:'none',
	},
	mydropcontainer:{
		position: 'relative',
		marginBottom:30
	},
	mydrop:{
		position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
    top: 45,
    padding: '0px',
    borderRadius: 10,
    zIndex: 100,
    right:-5,	
		overflow:'hidden'	
	},
	nojoincode:{
		textTransform: 'none',
		width:260,
		margin:4
	},
	nojcontainer:{
		textAlign:'right'
	}
}));

export default function LearnerForm(props) {

	const [openJoin, setOpenJoin] = useState(false);

	const  formname = "LearnerForm";

  const classes = useStyles();

	useEffect(() => {
		
	});

	const onNoJoinCode = (e) => {	
		let current = openJoin;
		setOpenJoin(!current);		
	}

	const onJoinCodeSelect = (e) => {
		let jc = e.currentTarget.dataset.joincode;
		props.formData['joincode'] = jc;

		let current = openJoin;
		setOpenJoin(!current);		
		//console.log(jc);
	}

	const onChange = (evt) =>{	
	  props.onTextChange(evt);
	}
  
	const registerClick = (name) =>{
		props.handleRegister(name);
	}

	const handleSetStep = (step) =>{
		props.handleClose();
	}

	const errors = props.formErrors;
	return (
		
		<div className="pa-f-learner">
		  <Grid className="pa-modal-content" container minWidth="md">
				<Grid className="viewLeft" item xs={12} sm={4}>
					<div className="leftHeading">Free and fun<br />online learning</div>
					<div className="line green"></div>						
					<p>By signing up for Pathways Awarua you agree to our:</p>					
					<div><a href="//live.pathwaysawarua.com/terms.aspx" rel="noreferrer" target="_blank">Terms and Conditions</a></div>
					<div><a href="/privacy" target="_blank">Privacy Policy</a></div>
				</Grid>
		
				<Grid className="viewRight" item xs={12} sm={8}>
				  <div><h3>Learners registration</h3></div>
					<Grid container spacing={1}> 
						<Grid item xs={12} sm={6}>
							<TextField error={errors['firstname'].error} helperText={errors['firstname'].msg}  key="firstname" name="firstname" value={props.formData.firstname} onChange={onChange} label="First name" type="text" variant="outlined" size="small" fullWidth={true}></TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField error={errors['lastname'].error} helperText={errors['lastname'].msg}  key="lastname" name="lastname" value={props.formData.lastname} onChange={onChange} label="Last name" type="text" variant="outlined" size="small" fullWidth={true}></TextField>
						</Grid>
					</Grid>
          <Grid item className="frow" spacing={1}><TextField error={errors['email'].error} helperText={errors['email'].msg} className="w100" key="emailui" value={props.formData.email} name="email" onChange={onChange} label="Email address" fullWidth={true} variant="outlined" size="small"></TextField> </Grid>
					<Grid item className="frow"  spacing={1}><TextField error={errors['username'].error} helperText={errors['username'].msg} className="w100" key="username" name="username" value={props.formData['username']} onChange={onChange} label="Username" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
					<Grid item className="frow"  spacing={1}><TextField error={errors['password'].error} helperText={errors['password'].msg} className="w100" key="password" name="password" type="password" value={props.formData['password']} onChange={onChange} label="Password" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
					<Grid item className="frow"  spacing={1}><TextField error={errors['confirmpassword'].error} helperText={errors['confirmpassword'].msg} className="w100" key="confirmpassword" name="confirmpassword" type="password" value={props.formData['confirmpassword']} onChange={onChange} label="Confirm password" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
					<Grid container className={classes.mydropcontainer}>

					<Grid item xs={12} sm={6}><TextField error={errors['joincode'].error} helperText={errors['joincode'].msg} key="joincode" name="joincode" value={props.formData['joincode']} onChange={onChange} label="Join code" variant="outlined" size="small"></TextField></Grid>
					<Grid item xs={12} sm={6} className={classes.nojcontainer}><Button className={classes.mybutton} key="nojoincode" name="nojoincode" type="text" variant="contained" onClick={onNoJoinCode}>No join code? Click here</Button>
						{openJoin && <div className={classes.mydrop}>
							<div><Button className={classes.nojoincode} onClick={onJoinCodeSelect} key="leftschool" data-joincode="1dc311" name="leftschool" type="text" variant="contained">I have left school</Button></div>
							<div><Button className={classes.nojoincode}  onClick={onJoinCodeSelect} key="secondaryschool" data-joincode="b6b48c" name="secondaryschool" type="text" variant="contained">I am still at secondary school</Button></div>
							<div><Button className={classes.nojoincode}  onClick={onJoinCodeSelect} key="overseas" name="overseas" data-joincode="aaf827" type="text" variant="contained">I live overseas</Button></div>
						</div>}</Grid>
					</Grid>

					<div className="formFooter">
						<Button type="button" name={formname} className="rounded green" onClick={() => registerClick(formname)}>Register</Button>
						<div className="login">Already registered? <span className="link" onClick={() => { handleSetStep(0); }}>Login</span></div>
					</div>

				</Grid>
			</Grid>
		</div>
	)
}
