import React, { useState, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import RegistrationService from './RegistrationService';
import EducatorsForm from './EducatorsForm';
import CulturalForm from './CulturalForm';
import axios from 'axios';
/// import empData from './data/DataBaseEmployerDetails.json';
/// import secData from './data/DataBaseSchoolDetails.json';
/// import terData from './data/DataBaseTertiaryDetails.json';

export default function EducatorTypeForm(props) {

	const [state, setState] = useState(0);
	const [open, setOpen] = useState(false);
	
	const [tertiaryItems,setTerItems] = useState([]);
	const [secondaryItems,setSecItems] = useState([]);
	const [employerItems,setEmpItems] = useState([]);

	 // eslint-disable-next-line
	const [haveTer, setHaveTer] = useState(false);
	 // eslint-disable-next-line
	const [haveEdu, setHaveEdu] = useState(false);
	 // eslint-disable-next-line
	const [haveEmp, setHaveEmp] = useState(false);
	
	const [formTitle, setFormTitle] = useState('NOTSET');
	const [selectedOrg, setOrg] = useState('');

	const formData = {};
	formData.firstname = '';
	formData.lastname = '';
	formData.email = '';
	formData.username = '';
	formData.password = '';
	formData.confirmpassword = ''
	formData.Class = '';
	formData.joincode = '';
	formData.termsandconditions = true;
	formData.formname = '';
	formData.orgitem = { id: 0, Name: '' };

	const [frmData, setfrmData] = useState(formData);
	// eslint-disable-next-line
	const [scroll, setScroll] = useState('body');

	const formErrors = {};
	formErrors.firstname = { error: false, msg: '' };
	formErrors.lastname = { error: false, msg: '' };
	formErrors.email = { error: false, msg: '' };
	formErrors.username = { error: false, msg: '' };
	formErrors.password = { error: false, msg: '' };
	formErrors.confirmpassword = { error: false, msg: '' };
	formErrors.Class = { error: false, msg: '' };
	formErrors.joincode = { error: false, msg: '' };
	formErrors.termsandconditions = { error: false, msg: '' };
	formErrors.Organisation = { error: false, msg: '' };
	formErrors.orgitem = { error: false, msg: '' };

	const [frmErrors, setfrmErrors] = useState(formErrors);
	const theme = useTheme();
	const fullScreen = false; useMediaQuery(theme.breakpoints.down('sm'));
	
	useEffect(() => {    
		const fetchTer = async () =>{
			const result = await axios(
				'https://pathwaysawarua.com/scripts/data/DataBaseTertiaryDetails.json',
			);    
			setTerItems(result.data);     
			setHaveTer(true);
		};    
		fetchTer();   
	},[]);

	useEffect(() => {
		const fetchEdu = async () =>{
			const result = await axios(
				'https://pathwaysawarua.com/scripts/data/DataBaseSchoolDetails.json',
			);    
			setSecItems(result.data);
			setHaveEdu(true);
		};
		fetchEdu();
	 },[]);
	
	
	 useEffect(() => {
		 const fetchEmp = async () =>{
			 const result = await axios(
				 'https://pathwaysawarua.com/scripts/data/DataBaseEmployerDetails.json',
			 );    
			 setEmpItems(result.data);
			 setHaveEmp(true);
		 };
		 fetchEmp();
		},[]);
	 
	const onEducator = (eduType, eduTitle) => {
		setState(eduType);
		setFormTitle(eduTitle);
		setOpen(true);
	}

	const onCultural = (eduType, eduTitle) => {
		//let data = frmData;
		//data['joincode'] = "03D975";
		//setfrmData({ ...frmData, data });

		setState(eduType);
		setFormTitle(eduTitle);
		setOpen(true);
	}

	const handleSetStep = (step) => {
		props.handleClose();
	}

	const handleClose = () => {
		setState(0);
		setFormTitle('');
		setOpen(false);
	}

	const handleTypeAheadSelected = (item) => {		
		let data = frmData;
		if(item != null)
		{			
			data.orgitem = item
			setOrg(item);
			setfrmData({ ...frmData, data });
			if(frmErrors.Organisation.error) {			
				let errors = frmErrors;
				errors.Organisation.error = false;
				errors.Organisation.msg = '';
				setfrmErrors({ ...frmErrors, errors });
			}
		}else{
			item = { id: 0, Name: '' }
			data.orgitem = item;
			setOrg(item);
			setfrmData({ ...frmData, data });
		}
		
	}
/*
	const culturalOptionSelected = (name) => {
		let data = frmData;
		if (name === "freeonline") {
			data['joincode'] = "03D975";
		}

		if (name === "ako") {
			data['joincode'] = "F89E32";
		}
		setfrmData({ ...frmData, data });
	}
*/
	const onTextFieldChange = (evt) => {
		let name = evt.target.name;
		let value = evt.target.value;
		let data = frmData;
		data[name] = value;
		//SAVE DATA
		setfrmData({ ...frmData, data });
		/// console.log(frmData);
	}

	const tryRegister = (formName) => {
    let data = frmData;
		data.formname = formTitle
		console.log(formTitle, formName);
		if(formTitle === 'cultural'){
			data.joincode = '03D975';
		}
		setfrmData({ ...frmData, data });		

    RegistrationService.TryRegister(frmData)
      .then(value => {
        console.log(value);
        let errors = frmErrors;
        
        for (const prop in errors) {
          errors[prop].error = false;
          errors[prop].msg = '';
        }

        // return type = 0 = initial validation errors
        if (value.rtype === 0) {
          if (Array.isArray(value.body)) {
            // For now assuming it is an array of errors


            value.body.forEach(element => {
              if (errors[element]) {
                errors[element].error = true;
                errors[element].msg = "Required";
                if (element === 'email')
                  errors[element].msg = "Email format not correct";
              }

            });
            setfrmErrors({ ...frmErrors, errors });
          }
        }
        // return type = 1 = errors from bracken 
        if (value.rtype === 1) {
          value.body.forEach(element => {
            if (element.remoteCode && element.remoteCode !== 0) {
              if (errors[element.name]) {
                errors[element.name].error = true;
                errors[element.name].msg = element.message ? element.message : "Value required."
              }
            }
          });
          setfrmErrors({ ...frmErrors, errors });
        }
      })
      .catch((reason) => {
        // handle reason;
        console.error(reason);
      });
  }

	const onFullRegisterClick = (formname) => {
		let data = frmData;
		data.formname = formTitle
		console.log(formTitle, formname);
		setfrmData({ ...frmData, data });		
		RegistrationService.TryRegisterFull(frmData)
			.then(value => {

				console.log(value);
				let errors = frmErrors;

				for (const prop in errors) {
					errors[prop].error = false;
					errors[prop].msg = '';
				}
				setfrmErrors({...frmErrors,errors});

				// return type = 0 = initial validation errors
				if (value.rtype === 0) {
					if (Array.isArray(value.body)) {
						// For now assuming it is an array of errors
						value.body.forEach(element => {
							if (errors[element]) {
								errors[element].error = true;
								errors[element].msg = "Required";
								if (element === 'email')
									errors[element].msg = "Email format not correct.";
								if (element === 'Organisation')
									errors[element].msg = "Organisation or school not found.";
							}
						});
						setfrmErrors({...frmErrors,errors});
					}
				}
				// return type = 1 = server valadation errors
				if (value.rtype === 1) {

					value.body.forEach(element => {
						if (element.remoteCode && element.remoteCode !== 0) {
							if (errors[element.name]) {
								errors[element.name].error = true;
								errors[element.name].msg = element.message ? element.message : "Value required."
							}
						}
					});
					setfrmErrors({...frmErrors,errors});
				}
			}) //end then
			.catch(error => {

			}); //end catch

	}

	const SelectionView = () => {
		return (
			<div className="pa-f-learner">
				<Grid className="pa-modal-content" container minWidth="md">
					<Grid className="viewLeft" item xs={12} sm={4}>
						<div className="leftHeading">Help learners build<br />their skills</div>
						<div className="line blue"></div>
						<p>Educators can create a class for learners
							and improve their own professional
							knowledge on Pathways Awarua.</p>
						
					</Grid>
					<Grid className="viewRight" item xs={12} sm={8} container>
						<h2 className="blue">Full educator account</h2>
						<p>(includes all educator functionality and access to Cultural Capabilities PLD)</p>
						<Grid container>
							<Grid item xs={12} sm={12} md={4}><Button type="button" onClick={() => { onEducator(5, "teriary") }} variant="outlined">Tertiary Educator</Button> </Grid>
							<Grid item xs={12} sm={12} md={4}><Button type="button" onClick={() => { onEducator(6, "secondary") }} variant="outlined">Secondary Teacher</Button></Grid>
							<Grid item xs={12} sm={12} md={4}><Button type="button" onClick={() => { onEducator(7, "employer") }} variant="outlined">Employer</Button></Grid>
						</Grid>
						<Grid container>
							<h2 className="blue">Cultural Capabilities account</h2>
							<p>(includes Cultural Capability pathways only, no educator functionality)</p>
							<div><Button type="button" onClick={() => { onCultural(8, "cultural") }} variant="outlined">Cultural Capabilities only</Button></div>
						</Grid>
						<div className="formFooter">
							<div className="login">Already registered? <span className="link" onClick={() => { handleSetStep(0); }}>Login</span></div>
						</div>
					</Grid>
				</Grid>
			</div>
		)
	}

	return (
		<div>
			<SelectionView />
			<Dialog open={open} onClose={handleClose} className="pa-d-root" fullScreen={fullScreen} maxWidth="md" scroll={scroll}>
				<div className="pa-d-container">
					<div id="daihead" className="pa-d-header">
						<IconButton aria-label="close" className="pa-d-close-btn" onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</div>
					{state === 5 && <EducatorsForm
						key={state + 10}
						title="Tertiary Educator"
						items={tertiaryItems}
						formname={formTitle}
						selectedOrgText={selectedOrg.Name}
						onItemSelected={handleTypeAheadSelected}
						formData={frmData}
						formErrors={frmErrors}
						change={onTextFieldChange}
						handleSetStep={handleSetStep}
						registerClick={onFullRegisterClick}
					/>}
					{state === 6 && <EducatorsForm
						key={state + 20}
						title="Secondary Teacher"
						items={secondaryItems}
						formname={formTitle}
						selectedOrgText={selectedOrg.Name}
						onItemSelected={handleTypeAheadSelected}
						formData={frmData}
						formErrors={frmErrors}
						change={onTextFieldChange}
						handleSetStep={handleSetStep}
						registerClick={onFullRegisterClick}
					/>}
					{state === 7 && <EducatorsForm
					  key={state + 30}
						title="Employer"
						items={employerItems}
						formname={formTitle}
						selectedOrgText={selectedOrg.Name}
						onItemSelected={handleTypeAheadSelected}
						formData={frmData}
						formErrors={frmErrors}
						change={onTextFieldChange}
						handleSetStep={handleSetStep}
						registerClick={onFullRegisterClick}
					/>}
					{state === 8 && <CulturalForm
					  key={state + 40}
						title="Cultural Capability"
						name={formTitle}
						formname={formTitle}
						formData={frmData}
						formErrors={frmErrors}
						change={onTextFieldChange}
						handleSetStep={handleSetStep}
						registerClick={tryRegister}
						handleClose={handleClose}
					/>}
				</div>
			</Dialog>
		</div>
	)
}