import React,{useEffect} from "react";
import { TextField, Grid } from '@material-ui/core';

export default function CulturalForm (props) {
	
	// const[optionSelected, setOptionSelected] = useState('');
	// const[folclass,setFolclass] = useState('');
	// const[akoclass,setAkoclass] = useState('');
	const formname = props.formname;
	const errors = props.formErrors;
/*
	const onOptionSelected = (e) => {

		let name = e.currentTarget.name;
    
		setOptionSelected(name)
		props.optChange(name);
	}
*/
	const registerClick = (formname) => {
		props.registerClick(formname);
	}

	const handleSetStep = (step) => {
		props.handleSetStep();
	}

	/*
		useEffect(() =>{
			let folclass = optionSelected === "freeonline" ? "freeonline selected" : "";
			let akoclass = optionSelected === "ako" ? "ako selected" : "";
			setFolclass(folclass);
			setAkoclass(akoclass);		 
		},[optionSelected])
	*/

		useEffect(() =>{
			// console.log("Errors changed");
		},[ props.formErrors])


	
		
		return (
			<div className="pa-f-learner">
				<Grid className="pa-modal-content" container  minWidth="md">
					<Grid className="viewLeft" item xs={12} sm={4} >
						<div className="leftHeading">Build your own<br />cultural capability</div>
						<div className="line orange"></div>
						<p>By signing up for Cultural Capabilities you agree to our:</p>
						<div><a href="//live.pathwaysawarua.com/terms.aspx" rel="noreferrer" target="_blank">Terms and Conditions</a></div>
						<div><a href="/privacy" target="_blank">Privacy Policy</a></div>
					</Grid>
					<Grid className="viewRight" item xs={12} sm={8}>
					<div><h3>{props.title}</h3></div>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={6}>
								<TextField error={errors['firstname'].error} helperText={errors['firstname'].msg} key="firstname" name="firstname" value={props.formData['firstname']} onChange={props.change} label="First name" type="text" variant="outlined" size="small" fullWidth={true}></TextField>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField error={errors['lastname'].error} helperText={errors['lastname'].msg} key="lastname" name="lastname" value={props.formData['lastname']} onChange={props.change} label="Last name" type="text" variant="outlined" size="small" fullWidth={true}></TextField>
							</Grid>
						</Grid>
						<Grid item className="frow" spacing={1}><TextField error={errors['email'].error} helperText={errors['email'].msg} className="w100" key="emailui" value={props.formData['email']} name="email" onChange={props.change} label="Email address" fullWidth={true} variant="outlined" size="small"></TextField> </Grid>
						<Grid item className="frow" spacing={1}><TextField error={errors['username'].error} helperText={errors['username'].msg} className="w100" key="username" name="username" value={props.formData['username']} onChange={props.change} label="Username" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
						<Grid item className="frow" spacing={1}><TextField error={errors['password'].error} helperText={errors['password'].msg} className="w100" key="password" name="password" type="password" value={props.formData['password']} onChange={props.change} label="Password" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
						<Grid item className="frow" spacing={1}><TextField error={errors['confirmpassword'].error} helperText={errors['confirmpassword'].msg} className="w100" key="confirmpassword" name="confirmpassword" type="password" value={props.formData['confirmpassword']} onChange={props.change} label="Confirm password" fullWidth={true} variant="outlined" size="small"></TextField></Grid>
						<Grid item className="frow" spacing={1}>
							<div className="formRow hidden"><TextField error={errors['joincode'].error} helperText={errors['joincode'].msg} key="joincode" name="joincode" value={props.formData['joincode']} onChange={props.change} label="Join code" variant="outlined" size="small"></TextField></div>
							<div className="formRow"><span className="error">{errors['joincode'].msg}</span></div>
						</Grid>
						<div className="formFooter">
							<button type="button" name="CultralForm" className="rounded orange" onClick={() =>registerClick(formname)}>Register</button>
							<div className="changedMind">Changed your mind? <span className="link" onClick={props.handleClose}>Full educator account</span></div>
							<div className="login">Already registered? <span className="link" onClick={() => { handleSetStep(0); }}>Login</span></div>
						</div>
					</Grid>
				</Grid>
			</div>
		)
	}
