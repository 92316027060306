// TypeAheadDropDown.js
import './TypeAheadDropDown.css'
import React from 'react';


export default class TypeAheadDropDown extends React.Component {
 constructor(props) {
   super(props);
   this.state = {
     suggestions: [],
     text:props.text,
     selectedItem:{}
   }
 }
  
 onTextChange = (e) => {
   const iteams = this.props.items;
   let suggestions = [];
   const value = e.target.value.toLowerCase();
   if (value.length > 0) {
    // const regex = new RegExp(`^${value}`, `i`);
     //suggestions = iteams.sort().filter(v => regex.test(v.id + v.Name));
     suggestions = iteams.sort().filter(v => (v.id + v.Name).toLowerCase().includes(value));
   }

   if(suggestions.length === 0){
    this.fireChange(null);
   }

   this.setState(() => ({
     suggestions,
     text:value
   }));
 }

 fireChange = (v) =>{
    if(this.props.handleSelectItem){
     this.props.handleSelectItem(v);
    }
 } 
 
 suggestionSelected=(value)=> {

  this.fireChange(value);

   this.setState({
     text: value.Name,
     suggestions:[],
     selectedItem:value
   });  
 }
 
 renderSuggestions = () => {
   let sugestionNumber = 8;
   let { suggestions } = this.state;
   // console.log("suggestions :",suggestions);
   if (suggestions.length === 0) {
    //  return (
    //    <div className="th-not-found">Sorry you organisation can't be found</div>
    //  );
    return null;
   }
   if (suggestions.length > sugestionNumber){
    suggestions = suggestions.slice(0,sugestionNumber - 1);
   }
   return (     
     <ul>
       {suggestions.map(city => <li key={city.id} onClick={(e)=>this.suggestionSelected(city)}>{city.id}-{city.Name}</li>)}
     </ul>
   )
 } 
 
 render() {
   const {text}=this.state
   
   return (
   <div className={this.props.error?"TypeAheadDropDown hasError":"TypeAheadDropDown"}>
     <input onChange={this.onTextChange} placeholder="Organisation name or number" value={text} type="text" />
     {this.renderSuggestions()}
   </div>
   );
 } 
}